export default [
  // {
  //   name: 'Dashboard',
  //   icon: 'nc-icon nc-bank',
  //   path: '/deliver-dashbord'
  // },
  {
    name: 'Order List',
    icon: 'nc-icon nc-bank',
    path: '/deliver-order-group',
    subpaths: ['Delivery Order Details','Delivery Order List Group','DeliverOrderLines']
  },
  {
    name: 'Profile',
    icon: 'nc-icon nc-bank',
    path: '/deliver-profile'
  },
  {
    name: 'Deliver Dispatch',
    icon: 'nc-icon nc-bank',
    path: '/deliver-dispatch',
    subpaths: ['Deliver Dispatch City']
  },
  {
    name: 'Delivery Rates',
    icon: 'nc-icon nc-bank',
    path: '/deliver-rates',
    subpaths: ['Delivery Rate Configure']
  },
  {
    name: 'Delivery Zones',
    icon: 'nc-icon nc-bank',
    path: '/deliver-zone-list',
    subpaths: ['Deliver Zone List']
  },
  {
    name: 'Pickup Addresses',
    icon: 'nc-icon nc-bank',
    path: '/deliver-pickup-address'
  }
  ]
