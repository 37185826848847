<template>
  <div
    class="wrapper"
    style="background-repeat: no-repeat;
  background-position: right top; background-attachment: fixed;"
  >
    <div>

    <!-- <div
      style="background-image: url(https://res.cloudinary.com/persystance-network/image/upload/q_auto/v1589124594/slt/portal_user/1589124594178.png);
  background-repeat: no-repeat; background-attachment: fixed;"
    > -->

    
      <div class="main-panel">
        <top-navbar></top-navbar>

        <div
          class="container"
          style="padding-top: 15px;padding-left: 12px;padding-right: 12px;background: #ffffff;"
        >
          <div class="row">
            <div class="col-sm-9 col-md-9">
              <ul class="breadcrumb">
                <li>
                  <a @click.prevent.stop="breadpush('/deliver-order-group')">
                    <i id="homeIcon" style="font-size: 30px; color: black" class="fa fa-home"></i>
                  </a>
                </li>
                <li v-for="item in lists" :key="item.text">
                  <a @click.prevent.stop="breadpush(item.href)">{{item.text}}</a>
                </li>
              </ul>
            </div>
            <div class="col-sm-2 col-md-2"></div>
          </div>
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 del-info-block">
              <div>
                <h3>Welcome : {{ fullName }}</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <aside class="col-sm-4 col-md-3 content-aside" id="column-left">
              <!-- <side-bar style="background-color: #ddd" type="sidebar" :sidebar-links="$sidebar.sidebarLinks">
              </side-bar>-->
              <BasicLeftMenu v-bind:data="this.leftmenudata" />
            </aside>
            <div class="col-md-9 col-sm-8" style="min-height: 700px; height: auto">
              <span v-if="showStages">
                <div v-if="currentstage < 5" class="container">
                  <ul class="progressbar">
                    <li :class="[currentstage >= 1 ? 'active': '']">Personal Information</li>
                    <li :class="[currentstage >= 2 ? 'active': '']">Addresses</li>
                    <!-- <li :class="[currentstage >= 3 ? 'active': '']">Delivery Settings</li> -->
                    <li :class="[currentstage >= 3 ? 'active': '']">
                      <span>Delivery Rates</span>
                    </li>
                    <!-- <li v-if="trackdata[4].order_line_status == 50" :class="[trackdata[4].order_line_status == 50 ? 'active': '']">Deliverd</li> -->
                  </ul>
                  <br />
                </div>
              </span>

              <dashboard-content style="background-color: white;"></dashboard-content>
            </div>
          </div>
        </div>

        <content-footer class="footer-container typefooter-1"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./DeliverDashboard/TopNavbar.vue";
import ContentFooter from "./DeliverDashboard/ContentFooter.vue";
import DashboardContent from "./DeliverDashboard/Content.vue";
import BasicLeftMenu from "../components/common/basic_left_menu";
import sidebarLinksDeliver from "../sidebarLinksDeliver";

import mixinProfile from "@/mixins/deliver/APIProfile";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    BasicLeftMenu,
    sidebarLinksDeliver
  },
  mixins: [mixinProfile],
  data: function() {
    return {
      menutitle: "Deliver Partner",
      breadcrumb: [
        {
          text: "Dashboard",
          href: "#",
          active: true
        }
      ],
      links: [],
      leftmenudata: {},
      store: [],
      selected_store: "",
      currentstage: 1,
      showStages: false,
      fullName: ""
    };
  },
  computed: {
    lists: {
      get() {
        return this.$store.state.breadcrumb.breadcrumb.payload;
      }
    }
  },
  watch: {
    "$store.state.delivery_full_name"() {
      if (this.$store.state.delivery_full_name === null) {
        this.getDelInfo();
      } else {
        this.fullName = "";
        this.fullName = this.$store.state.delivery_full_name;
      }
    },
    $route(to, from) {
      this.leftMenuGen();
      this.zoomContainerRemove();
      this.checkRequiredStage();
    },
    "$store.state.deliverystage": function() {
      this.checkRequiredStage();
    },
    "$store.state.pickup_value_delivery": function() {
      this.leftMenuGen();
    },
    selected_store: function(val) {
      this.$store.dispatch("fetchDeliver_selectedstore");
    }
  },
  beforemounted() {
    /* ---------------------------------------------------
        Back to Top
      -------------------------------------------------- */
    $(".back-to-top").addClass("hidden-top");
    $(window).scroll(function() {
      if ($(this).scrollTop() === 0) {
        $(".back-to-top").addClass("hidden-top");
      } else {
        $(".back-to-top").removeClass("hidden-top");
      }
    });

    $(".back-to-top").click(function() {
      $("body,html").animate({ scrollTop: 0 }, 1200);
      return false;
    });
  },
  created() {
    this.handleBreadcrumb();
    this.leftMenuGen();
    this.zoomContainerRemove();
    this.checkRequiredStage();
    this.getDelInfo();
  },
  methods: {
    getDelInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.fullName = response.first_name + " " + response.last_name;
      } catch (error) {
        console.log(error);
      }
    },
    checkRequiredStage: async function() {
      let mdata = JSON.parse(localStorage.deliver_data);
      this.account_type = mdata.account_type;
      let response = await this.getDashboardStage();

      if (response !== null) {
        this.showStages = true;
        this.currentstage = response.complated_status;
        if (response.complated_status === 4) {
          this.showStages = false;
        }
        return response.complated_status;
      } else {
        this.showStages = false;
        this.currentstage = 1;
        return null;
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    zoomContainerRemove() {
      $(".zoomContainer").each(function() {
        //if statement here
        // use $(this) to reference the current div in the loop
        //you can try something like...
        $(this).remove();
      });
    },
    breadpush(pathlink) {
      this.$router.push(pathlink).catch(err => {});
    },
    leftMenuGen: async function() {
      try {
        this.links = sidebarLinksDeliver;
        if (
          this.links.length === 4 &&
          this.$store.state.pickup_value_delivery !== null
        ) {
          this.links = [
            {
              name: "Dashboard",
              icon: "nc-icon nc-bank",
              path: "/deliver-dashbord"
            },
            {
              name: "Profile",
              icon: "nc-icon nc-bank",
              path: "/deliver-profile"
            },
            {
              name: "Pickup Addresses",
              icon: "nc-icon nc-bank",
              path: "/deliver-pickup-address"
            },
            {
              name: "Delivery Rates",
              icon: "nc-icon nc-bank",
              path: "/deliver-rates",
              subpaths: ["Delivery Rate Configure"]
            },
            {
              name: "Delivery Order List",
              icon: "nc-icon nc-bank",
              path: "/deliver-order-list",
              subpaths: ["Delivery Order Details"]
            }
          ];
        }
        if (this.$store.state.pickup_value_delivery === null) {
          let res = await this.getProfileBacicInfo();
          if (res.pickup === true) {
            for (let x = 0; x < this.links.length; x++) {
              if (this.links[x].name === "Pickup Addresses") {
                this.links.splice(x, 1);
              }
            }
          }
        } else {
          if (this.$store.state.pickup_value_delivery === true) {
            for (let x = 0; x < this.links.length; x++) {
              if (this.links[x].name === "Pickup Addresses") {
                this.links.splice(x, 1);
              }
            }
          }
        }
        this.links.forEach(element => {
          if (element.subpaths) {
            if (
              element.subpaths.includes(this.$route.name) ||
              element.path === this.$route.path
            ) {
              element.active = true;
            } else {
              element.active = false;
            }
          } else {
            if (element.path === this.$route.path) {
              element.active = true;
            } else {
              element.active = false;
            }
          }
        });
        this.leftmenudata = { title: "menu", linksArray: this.links };
      } catch (error) {
        throw error;
      }
    }
  },
  onIdle() {
    // eslint-disable-next-line
    // console.log('onIdle')
    // swal({
    //   title: `Here's a message!`,
    //   text: `It's pretty, isn't it?`,
    //   buttonsStyling: false,
    //   confirmButtonClass: 'btn btn-info btn-fill'
    // })
  },
  onAction() {
    // console.log('onIdle')
  },
  idleTime() {
    // console.log('onIdle time')
  }
};
</script>
<style scoped>
.well {
  background-color: transparent !important;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}

/* three items */
.progressbar li:first-child:nth-last-child(4),
.progressbar li:first-child:nth-last-child(4) ~ li {
  width: 25%;
}

#homeIcon:hover,
#homeIcon:active,
#homeIcon:after {
  color:#5779ae !important;
}

.progressbar li:first-child:nth-last-child(5),
.progressbar li:first-child:nth-last-child(5) ~ li {
  width: 20%;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  /* content: counter(step); */
  content: no-close-quote;
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: rgb(77, 161, 48);
}
.progressbar li.active:before {
  border-color: rgb(77, 161, 48);
  /* background-color: rgb(77, 161, 48); */
  /* background-image: url('../../../../assets/image/sample_product_images/confirm1.png'); */
  background-image: url("/assets/image/sample_product_images/confirm1.png");
  background-size: contain;
}
.progressbar li.active + li:after {
  background-color: rgb(77, 161, 48);
}

.progressbar li.dfailed:before {
  border-color: red;
  background-color: red !important;
  /* background-image: url('../../../../assets/image/sample_product_images/confirm1.png'); */
  background-image: url("https://www.shareicon.net/data/256x256/2015/09/15/101562_incorrect_512x512.png");
  background-size: contain;
}

.home-bedcrumb {
  font-size: 20px;
  color: #000000;
}
ul.breadcrumb li:not(:last-child):after {
  font-family: "FontAwesome";
  content: none !important;
  font-size: 13px;
  top: 0px;
  right: 0;
  position: absolute;
}
ul.breadcrumb li {
  display: inline-block;
  padding: 0 17px 0 8px;
  margin-right: 0px;
  position: relative;
}
ul.breadcrumb li:hover a {
  color: #5779ae;
}
ul.breadcrumb li:last-child a {
  color: #5779ae;
}
.del-info-block {
  padding-left: 5.5%;
}
.del-info-block h3 {
  margin-top: 0px;
}
@media (min-width: 768px) {
  .scoped {
    width: 36%;
  }
}
@media (min-width: 1280px) {
  .scoped {
    width: 31%;
  }
}
@media (min-width: 1440px) {
  .scoped {
    width: 31%;
  }
}
@media (min-width: 1800px) {
  .scoped {
    width: 25%;
  }
}
@media (min-width: 1880px) {
  .scoped {
    width: 25%;
  }
}
@media (min-width: 2880px) {
  .scoped {
    width: 25%;
  }
}
</style>
